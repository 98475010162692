<template>
  <v-dialog
    width="550"
    persistent
    @update:model-value="reason = null"
  >
    <template #activator="{ props }">
      <v-runtime-template
        v-bind="props"
        :template="button"
      />
    </template>
    <template #default="{ isActive }">
      <v-card >
        <!-- eslint-disable vue/no-v-html -->
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <v-card-title v-html="$translate(`${translationName}.${actionName}.title`, {...item})" />
        <v-card-text v-html="$translate(`${translationName}.${actionName}.text`, {...item})" />

        <v-card-text
          v-if="withTextarea"
          class=""
        >
          <v-textarea
            v-model="reason"
            label="Raison"
            variant="outlined"
          />
        </v-card-text>
        <v-card-actions class="border-t pa-4">
                <v-row class="pr-2 py-2 pl-1">
                  <v-col cols="6" class="py-0">
                    <v-btn block
                      variant="outlined"
                      @click="isActive.value = false"
                    >
                      Annuler
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right py-0">
                    <v-btn
                      color="danger" variant="flat" block
                      :disabled="withTextarea ? !reason : false"
                      @click="$emit('deleteData', {...item, reason}); isActive.value = false"
                    >
                      {{ $translate(`${translationName}.${actionName}`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import VRuntimeTemplate from "vue3-runtime-template";

export default {
    name : "ListDeleteConfirmation",
    components : {
        VRuntimeTemplate,
    },
    props: {
        item : { type: Object, required: true },
        translationName : { type: String, required: true },
        actionName : { type: String, required: false, default : "delete" },
        withTextarea : { type : Boolean, required : false },
        button : { type : String , required : true },
    },
emits: ['deleteData'],
    data () {
        return {
            reason : null,
        }
    },
};
</script>
